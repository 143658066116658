import { message } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import GoogleService from "../../../service/GoogleService";

export default function GoogleCalendarResponse() {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      message.error("No authorization code found");
      return;
    }

    const finalizeAuth = async () => {
      try {
        await GoogleService.requestTokenGoogleCalendar({ code });
        window.location.href = "/dashboard/vacancy";
      } catch (err) {
        message.error(err.message || "Failed to connect Google Calendar");
      }
    };

    finalizeAuth();
  }, [searchParams]);

  return <></>;
}
