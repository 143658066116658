import React, { useEffect } from "react";

const PhoneCallOnly = () => {
  useEffect(() => {
    localStorage.isPhoneCallOnly = "true";
    window.location.href = "/";
  }, []);
  return <></>;
};

export default PhoneCallOnly;
