import { Badge, Button, Space } from "antd";
import { forwardRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { BiSolidTimer } from "react-icons/bi";
import { FaFileImport } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { LuWorkflow } from "react-icons/lu";
import { MdDelete, MdPreview } from "react-icons/md";
import { PiExportFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/auth/selectors";
import withDroppable from "../../../withDroppable";
import { pickPropOut } from "../../services/utils";
import Card from "./components/Card";
import CardAdder from "./components/CardAdder";

const ColumnEmptyPlaceholder = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ minHeight: "inherit", height: "inherit" }}
    {...props}
  />
));

const DroppableColumn = withDroppable(ColumnEmptyPlaceholder);

function Column({
  children,
  index: columnIndex,
  renderCard,
  renderColumnHeader,
  disableColumnDrag,
  disableCardDrag,
  onCardNew,
  allowAddCard,
  loadMore,
  onCardOptionClick,
  VacancyId,
  columnId,
}) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  return (
    <Draggable
      draggableId={`column-draggable-${children.id}`}
      index={columnIndex}
      isDragDisabled={disableColumnDrag}
    >
      {(columnProvided) => {
        const draggablePropsWithoutStyle = pickPropOut(
          columnProvided.draggableProps,
          "style"
        );

        return (
          <div
            ref={columnProvided.innerRef}
            {...draggablePropsWithoutStyle}
            style={{
              height: "100%",
              minHeight: "28px",
              display: "inline-block",
              verticalAlign: "top",
              ...columnProvided.draggableProps.style,
            }}
            className="react-kanban-column dark:bg-gray-800"
            data-testid={`column-${children.id}`}
          >
            <div {...columnProvided.dragHandleProps}>
              <div className="flex items-start justify-between">
                {renderColumnHeader(children)}
                <div>
                  <Space className="w-full flex justify-end">
                    {VacancyId && columnId !== "UNCATEGORIZED" && (
                      <a
                        href="#"
                        onClick={() =>
                          onCardOptionClick(children.id, "workflow")
                        }
                      >
                        <LuWorkflow title={t("Stage Workflows")} />
                      </a>
                    )}
                    {VacancyId && columnId !== "UNCATEGORIZED" && (
                      <a
                        href="#"
                        onClick={() =>
                          onCardOptionClick(children.id, "add-candidate")
                        }
                      >
                        <IoMdAddCircle title={t("Add Lead")} />
                      </a>
                    )}
                    {columnId !== "UNCATEGORIZED" && (
                      <a
                        href="#"
                        onClick={() =>
                          onCardOptionClick(children.id, "delete-column")
                        }
                      >
                        <MdDelete
                          className="text-red-500"
                          size={18}
                          title={t("Delete")}
                        />
                      </a>
                    )}
                  </Space>
                  <Space className="w-full flex justify-end">
                    {VacancyId && columnId !== "UNCATEGORIZED" && (
                      <a
                        href="#"
                        onClick={() => onCardOptionClick(children.id, "import")}
                      >
                        <FaFileImport title={t("Import")} />
                      </a>
                    )}
                    <a
                      href="#"
                      onClick={() => onCardOptionClick(children.id, "export")}
                    >
                      <PiExportFill title={t("Export")} />
                    </a>
                    {!(user?.accessPhoneCallOutbound && !user?.accessAny) && (
                      <a
                        href="#"
                        onClick={() =>
                          onCardOptionClick(children.id, "delayed-automation")
                        }
                      >
                        <BiSolidTimer title={t("Email Sequence")} />
                      </a>
                    )}
                    <Badge
                      count={children?.candidateCount ?? 0}
                      offset={[0, 0]}
                      overflowCount={999}
                    ></Badge>
                  </Space>
                </div>
              </div>
            </div>
            {allowAddCard && (
              <CardAdder column={children} onConfirm={onCardNew} />
            )}
            <DroppableColumn droppableId={String(children.id)}>
              {children.cards.length ? (
                children.cards.map((card, index) => (
                  <Card
                    key={card.id}
                    index={index}
                    renderCard={(dragging) =>
                      renderCard(children, card, dragging)
                    }
                    disableCardDrag={disableCardDrag}
                  >
                    {card}
                  </Card>
                ))
              ) : (
                <div className="react-kanban-card-skeleton" />
              )}

              {children.canLoadMore && (
                <Button onClick={loadMore}>{t("Load More")}</Button>
              )}
            </DroppableColumn>
          </div>
        );
      }}
    </Draggable>
  );
}

export default Column;
