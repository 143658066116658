import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthService from "../../../service/AuthService";

export function GoogleCalendarAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await AuthService.me();
      setIsAuthenticated(!!response.data.me.connectGoogleCalendar);
    } catch (error) {
      console.error("Error checking auth status:", error);
    }
  };

  const handleAuth = async () => {
    try {
      const response = await AuthService.getGoogleCalendarAuthUrl();
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error("Error getting auth URL:", error);
    }
  };

  if (isAuthenticated) {
    return <p>{t("Google Calendar is connected.")}</p>;
  }

  return <Button onClick={handleAuth}>{t("Connect Google Calendar")}</Button>;
}
