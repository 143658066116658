import { SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Input, List } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PublicService from "../../../service/PublicService";

const { TextArea } = Input;

const getOrCreateUUID = (webinarId) => {
  const storageKey = `webinar_uuid_${webinarId}`;
  let uuid = localStorage.getItem(storageKey);
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem(storageKey, uuid);
  }
  return uuid;
};

const getOrCreateViewerCount = () => {
  let count = localStorage.getItem("webinar_viewer_count");
  if (!count) {
    count = (Math.floor(Math.random() * (1000 - 200 + 1)) + 200).toString();
    localStorage.setItem("webinar_viewer_count", count);
  }
  return parseInt(count);
};

export default function PublicWebinar() {
  let { id } = useParams();
  const [webinar, setWebinar] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [uuid] = useState(() => getOrCreateUUID(id));
  const [viewerCount, setViewerCount] = useState(getOrCreateViewerCount());

  const fetchWebinar = useCallback(async () => {
    try {
      const response = await PublicService.getWebinar(id);
      setWebinar(response.data);
    } catch (error) {
      console.error("Failed to fetch webinar:", error);
    }
  }, [id]);

  const fetchComments = useCallback(async () => {
    try {
      const response = await PublicService.getWebinarComments(id, uuid);
      setComments(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  }, [id, uuid]);

  const handleCommentSubmit = async () => {
    try {
      await PublicService.addWebinarComment(id, newComment, uuid);
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  const generateAIComment = useCallback(async () => {
    try {
      await PublicService.webinarAIComment(id);
      fetchComments();
    } catch (error) {
      console.error("Failed to generate AI comment:", error);
    }
  }, [id, webinar]);

  useEffect(() => {
    if (id) {
      fetchWebinar();
      fetchComments();
    }
  }, [id, fetchWebinar, fetchComments]);

  useEffect(() => {
    const aiInterval = setInterval(generateAIComment, 20000);
    return () => clearInterval(aiInterval);
  }, [generateAIComment]);

  useEffect(() => {
    const viewerInterval = setInterval(() => {
      setViewerCount((prevCount) => {
        const change = Math.floor(Math.random() * 11) - 5; // Random number between -5 and 5
        const newCount = Math.max(200, Math.min(1000, prevCount + change));
        localStorage.setItem("webinar_viewer_count", newCount.toString());
        return newCount;
      });
    }, 15000);
    return () => clearInterval(viewerInterval);
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{webinar?.title}</h1>
      <Card>
        <video
          className="w-full aspect-video mb-4"
          autoPlay
          src={webinar?.videoUrl}
        >
          Your browser does not support the video tag.
        </video>
        <p className="mb-4">{webinar?.description}</p>
        <p className="text-sm text-gray-500">{viewerCount} viewers</p>
      </Card>

      <Card className="mt-4">
        <h2 className="text-xl font-semibold mb-4">Comments</h2>

        <div className="mt-4">
          <TextArea
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleCommentSubmit}
            className="mt-2"
          >
            Post Comment
          </Button>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(comment) => (
            <List.Item>
              <List.Item.Meta
                title={comment.fullname || "You"}
                description={comment.content}
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}
