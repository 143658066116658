import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PublicService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getPartnerConfig() {
    return this.api.post("/getPartnerConfig", { origin: window.location.host });
  }
  signupPartner(data) {
    return this.api.post("/signupPartner", {
      domain: window.location.host,
      ...data,
    });
  }
  getVacancyData(id) {
    return this.api.get(`/getVacancyData?id=${id}`);
  }
  getFunnelSteps(id) {
    return this.api.get(`/getFunnelSteps?id=${id}`);
  }
  createVacancySubmission(data) {
    return this.api.post("/createVacancySubmission", {
      domain: window.location.host,
      ...data,
      baseURL: window.location.origin,
    });
  }
  clickFunnel(data) {
    return this.api.post("/clickFunnel", {
      domain: window.location.host,
      ...data,
    });
  }
  ctaClick(data) {
    return this.api.put("/ctaClick", {
      domain: window.location.host,
      ...data,
    });
  }

  getSurvey(token, checkExpired) {
    return this.api.get(
      `/getSurvey?token=${token}&checkExpired=${checkExpired}`
    );
  }
  getRecruiterData(id, candidateId) {
    return this.api.get(
      `/getRecruiterData?id=${id}&candidateId=${candidateId}`
    );
  }
  linkedinPeopleSearch(keywords, count) {
    return this.api.post(`/linkedinPeopleSearch`, { keywords, count });
  }
  getPDFText(url) {
    return this.api.post(`/getPDFText`, { url });
  }
  queryJobsOfRecruiter({ page = 1, limit = 10, recruiterId, text }) {
    return this.api.post(`/queryJobsOfRecruiter`, {
      page,
      limit,
      recruiterId,
      text,
    });
  }
  queryJobs({ page = 1, limit = 10, text }) {
    return this.api.post(`/queryJobs`, {
      page,
      limit,
      text,
    });
  }
  generateCandidateToken(click_id) {
    return this.api.post(`/generateCandidateToken`, { click_id });
  }
  remindCV(click_id) {
    return this.api.post(`/remindCV`, { click_id });
  }
  getDocumentData(k) {
    return this.api.get(`/getDocumentData?token=${k}`);
  }
  publicCandidateUpdate(data) {
    return this.api.post(`/publicCandidateUpdate`, data);
  }
  getDocumentDataHiringManager(k) {
    return this.api.get(`/getDocumentDataHiringManager?token=${k}`);
  }
  publicHiringManagerUpdate(data) {
    return this.api.post(`/publicHiringManagerUpdate`, data);
  }
  submitSurvey(data) {
    return this.api.post("/submitSurvey", {
      domain: window.location.host,
      ...data,
    });
  }
  replicateGen({ input, id }) {
    return this.api.post(`/replicateGen`, { input, id });
  }
  aiPrompt({ content }) {
    return this.api.post(`/aiPrompt`, { content });
  }
  cloudinarySearch(data) {
    return this.api.post(`/cloudinarySearch`, data);
  }
  moveWorkflow({ stage, candidate, req, previousStageId, newLead }) {
    return this.api.post(`/moveWorkflow`, {
      stage,
      candidate,
      req,
      previousStageId,
      newLead,
    });
  }

  getWebinar(id) {
    return this.api.get(`/getWebinar?id=${id}`);
  }
  getWebinarComments(id) {
    return this.api.get(
      `/getWebinarComments?id=${id}&uuid=${localStorage.getItem(
        `webinar_uuid_${id}`
      )}`
    );
  }
  addWebinarComment(id, content) {
    return this.api.post(`/addWebinarComment?id=${id}`, {
      content,
      uuid: localStorage.getItem(`webinar_uuid_${id}`),
    });
  }
  webinarAIComment(id) {
    return this.api.post(`/webinarAIComment?id=${id}`, {
      uuid: localStorage.getItem(`webinar_uuid_${id}`),
    });
  }
}

export default new PublicService(`${getBackendUrl()}/public`);
