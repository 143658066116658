import { Allotment } from "allotment";
import "allotment/dist/style.css";
import {
  Alert,
  Divider,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCopy, FaExternalLinkAlt } from "react-icons/fa";
import { GrInfo } from "react-icons/gr";
import { MdArrowBackIos } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloudinaryUpload from "../../../components/CloudinaryUpload";
import { selectUser } from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";
import EmailSequencing from "./EmailSequencing";
import FunnelStats from "./FunnelStats";
import LTS from "./LTS";
import PhoneConfiguration from "./PhoneConfiguration";
import Webinar from "./Webinar";

const VacancyDetails = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    CrudService.getSingle("Vacancy", id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      {vacancyData && (
        <>
          <div
            className="mb-2 flex justify-start gap-2 items-center cursor-pointer text-sm"
            onClick={() =>
              navigate(
                user?.accessPhoneCallOutbound && !user?.accessAny
                  ? "/dashboard/myvacancies"
                  : "/dashboard/outboundcalls"
              )
            }
          >
            <MdArrowBackIos size={13} /> <div>{t("Back to Funnels")}</div>
          </div>
          <h1 className="font-bold mb-4 text-lg">
            LTS: {vacancyData?.alternativeName || vacancyData?.name}
          </h1>
        </>
      )}

      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: t("Leads"),
            children: <LTS VacancyId={vacancyData._id} />,
          },
          {
            key: "2",
            label: t("Email Sequencing"),
            children: <EmailSequencing />,
            hide: user?.accessPhoneCallOutbound && !user?.accessAny,
          },
          {
            key: "3",
            label: t("Stats"),
            children: <FunnelStats />,
            hide: user?.accessPhoneCallOutbound && !user?.accessAny,
          },
          {
            key: "4",
            label: t("Phone Configuration"),
            children: (
              <PhoneConfiguration
                vacancyData={vacancyData}
                onChange={async (e) => {
                  if (!vacancyData._id) return;

                  setVacancyData((x) => ({ ...x, ...e }));
                  await CrudService.update("Vacancy", vacancyData._id, {
                    ...e,
                    _id: undefined,
                  });
                  message.success("Saved");
                }}
              />
            ),
          },
          {
            key: "5",
            label: t("Webinar"),
            children: <Webinar vacancyId={searchParams.get("id")} />,
          },
        ].filter((x) => !x?.hide)}
      />
    </>
  );
};

export default VacancyDetails;
